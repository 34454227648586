import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DefinitionsService } from 'src/app/services/definitions.service';
import { MatDialog } from '@angular/material/dialog';
import { MenuService } from 'src/app/services/menu.service';
import { isNil } from 'lodash';
import { SocketService } from 'src/app/services/socket.service';
import { SubscriptionContainer } from 'src/app/models/subscription-container';

@Component({
  selector: 'app-definitions',
  templateUrl: './definitions.component.html',
  styleUrls: ['./definitions.component.scss']
})
export class DefinitionsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  @Input() displayedColumns: string[] = ['name', 'project', 'description', 'asset', 'lastModified', 'actions'];
  public availableColumns = ['host', 'name', 'project', 'path', 'description', 'asset', 'lastModified', 'tags', 'user'];

  @Input() teamName: string;
  @Input() isWidget: string;
  @Input() parentId: string;
  @Input() filter: string;

  @Output() definitionClick = new EventEmitter<{ parentId: string; rdsName: string }>();
  private _subs = new SubscriptionContainer();

  sortOrder: string[] = ['isRunning'];
  autoColumns: string[] = [];
  dataSource = new MatTableDataSource<any>([]);
  manualColumns = ['isRunning', 'tags'];
  pageSize = 10;
  isLoading = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private definitionsService: DefinitionsService,
    public dialog: MatDialog,
    private menuService: MenuService,
    private _socket: SocketService
  ) {}

  ngOnInit(): void {
    (<any>window).definitions = this;
    this.subscribeToSocketEvents();
    if (this.isWidget) {
      this.load().then(() => {
        this.applyFilter(this.filter);
      });
    } else {
      this.activatedRoute.params.subscribe((params) => {
        this.teamName = params.team;
        this.filter = this.activatedRoute.snapshot.queryParamMap.get('filter');
        this.load().then(() => {
          this.applyFilter(this.activatedRoute.snapshot.queryParamMap.get('filter'));
        });
      });
    }

    this.menuService.delayedSetActive('definitions');
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this._socket.leaveRoom();
  }

  applyFilter(filterValue: string) {
    if (isNil(filterValue)) return;

    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) this.dataSource.paginator.firstPage();
  }

  public subscribeToSocketEvents(): void {
    this._socket.joinRoom();

    this._subs.add = this._socket.subscribeToRoomEvents('definitions', (packet: any) => {
      var msg: string = packet.msg;
      const prefix = 'update definition index';
      if (msg.startsWith(prefix)) {
        this.load();
      }
    });
  }

  public async load() {
    const response = await this.definitionsService.loadAsync(this.teamName);
    const dateFields = ['created', 'lastModified'];
    response.forEach((row) => {
      dateFields.forEach((field) => {
        row[field] = new Date(row[field] * 1000);
      });
    });
    this.isLoading = false;
    this.dataSource.data = response;
  }

  openFile(_host: string, name: string) {
    if (this.isWidget) {
      this.definitionClick.emit({ parentId: this.parentId, rdsName: name });
    } else {
      this.router.navigate(['/', this.teamName, 'model', 'definition', name]);
    }
  }

  add() {
    if (this.isWidget) {
      this.definitionClick.emit({ parentId: this.parentId, rdsName: 'add' });
    } else {
      this.router.navigate(['/', this.teamName, 'model', 'definition', 'add']);
    }
  }

  async delete(row, $event) {
    $event.stopPropagation();
    if (!confirm(`Are you sure you want to delete this ${row.name}?`)) return;

    this.dataSource.data = this.dataSource.data.filter((x) => x.name !== row.name);
    const sub = this.definitionsService.remove(this.teamName, row.name).subscribe((results) => {
      sub.unsubscribe();
    });
  }

  async duplicate(row, $event) {
    $event.stopPropagation();
    await this.definitionsService.duplicateAsync(this.teamName, row.name);
    await this.load();
  }

  displayAsset(row) {
    if (row.asset.length < 3) return row.asset.join(',');

    const retval = `${row.asset.slice(0, 2).join(',')} (+ ${row.asset.length - 2} more)`;
    return retval;
  }
}
