import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public static get initialized(): boolean {
    return environment['initialized'] ?? false;
  }

  public static set initialized(value: boolean) {
    environment['initialized'] = value;
  }

  public static get apiUrl(): string {
    return environment.apiUrl;
  }

  public static get suppressErrors(): boolean {
    return environment['features']['suppress_errors'] ?? false;
  }

  public static get udfUrl(): string {
    return environment.udfUrl;
  }

  public static get chatUrl(): string {
    return environment.chatUrl;
  }

  public static get socketUrl(): string {
    return environment.socketUrl;
  }

  public static get canvasUrl(): string {
    return environment.canvasUrl;
  }

  public static get hostUrl(): string {
    return environment.hostUrl;
  }

  public static get chartUrl(): string {
    return environment.chartUrl;
  }

  public static get litDir(): string {
    return environment.litDir;
  }

  public static get magicNum(): string {
    return '###4C4954###';
  }

  public static get features(): any {
    return environment.features;
  }

  public static get keycloakUrl(): string {
    return environment.keycloakUrl;
  }

  public static get keycloakRealm(): string {
    return environment.keycloakRealm;
  }

  public static get keycloakClientId(): string {
    return environment.keycloakClientId;
  }

  public static get saasMode(): boolean {
    return Boolean(environment.saasMode);
  }

  public static get scichartLicenseKey(): string {
    // return environment.scichartLicenseKey;
    return 'Os33Y4wTqQWuHdeEMyw4tGucRAexh2AWSJjKRS+exF3kJAJ67dkJZoXs5UuK1sJ+CEWSOpoVxu5ocARCtoI0+hhhl5Ko+a37ZzUMrUKntxFhmr255HAYl/PgYaAmJrXg+8471E9YX0SKyjz1+G5UWLP21Wv/CL6aSW0NOCaYt1R4TbtbpWnk9C6ze/RjlEaxWhmn+Ri/I2CNV/eUl3ulmBB5aLblNSEDB2aGnnVBTfV1WJOm8UgIvjYUlg1j0LSqQptMCwUKFueLxtaOFSwzCoRXyLy2btfsn/Lj2WULNHMmqws9/vecj1HmNeQV8aaK+G+9IcEELDxIWZJLvKYThYR7D06piaqlHK8r8PBe09DqkmPN1xDi7ZsBTICguzMMidqnphxeZMHSBxZSwNXht0s0VDiZ5yRiQLib0SiAufXHhs1U/adl6HFPS1IuLcmGY35FOQhMiJnqsknJ3XA5HOZSdRK/B9LBv+jbHKjT+GVr5nXPr5p1CgRYmz7YvjTjjSWYs/cyklvAZOa5tcWBCMu0Sgdb2fopSEKCzpIZT8dQq/9UlTmry/VzQwidSmST4BUX3lo456yplaKfyjj/g/0Mo0j3D8KP7DTDivSA9VUy7TQ0zppskZRx12xSUun5uHhD+nImXV8RkFrvDkrPDxJnBkRso+LZSRvkJpmn4gQOlfVr5g==';
  }
  constructor() {}


}
