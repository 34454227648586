import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { ChatService } from 'src/app/services/chat.service';
import { MatListModule } from '@angular/material/list';
import { IdentityService } from 'src/app/services/identity.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-project-board-widget',
  templateUrl: './project-board-widget.component.html',
  styleUrls: ['../base-widget/base-widget.component.scss', './project-board-widget.component.scss']
})
export class ProjectBoardWidgetComponent extends BaseWidgetComponent implements OnInit, OnDestroy {
  @ViewChild('content', { static: true }) content: ElementRef<HTMLDivElement>;
  @ViewChild('chatInput') chatInput!: ElementRef;

  @Output() onViewExperiments = new EventEmitter<{ projectName: string }>();
  @Output() onViewDefinition = new EventEmitter<{ projectName: string; definitionName: string }>();
  @Output() onViewBuild = new EventEmitter<{ projectName: string; runid: number }>();
  @Output() onViewAsset = new EventEmitter<{ projectName: string; assetName: string }>();
  @Output() onViewFeatureExplorer = new EventEmitter<{ projectName: string }>();
  @Output() onViewVault = new EventEmitter<{ projectName: string }>();
  @Output() onViewUpload = new EventEmitter();

  public outputs: any[] = [];
  public input: string = null;
  public loading = false;

  private _sessionId: string = null;
  constructor(
    private _chatService: ChatService,
    private _identity: IdentityService
  ) {
    super();
  }

  ngOnInit() {
    this._sessionId = this._identity.me.username;
    if ((<any>window).chat) {
      (<any>window).chat.push(this);
    } else {
      (<any>window).chat = [this];
    }

    this.getHistory();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  async getHistory() {
    if (!ConfigService.chatUrl) {
      console.error('ChatUrl not found.');
      return;
    }
    const sub = this._chatService.history(this._sessionId).subscribe((response: any) => {
      sub.unsubscribe();
      for (let item of response) {
        this.outputs.push(item);
      }
      this.scrollToBottom();
    });
  }

  scrollToBottom(): void {
    try {
      setTimeout(() => {
        this.content.nativeElement.scrollTop = this.content.nativeElement.scrollHeight;
      }, 100);
    } catch (err) {}
  }

  async processInput($event) {
    this.loading = true;
    const savedInput = this.input;
    if(savedInput.startsWith("clear")) {
      this.outputs = [];
      this.loading = true;
      return;
    }

    const sub = this._chatService
      .chat(this.teamName, this._identity.me.username, savedInput, this._sessionId, this._identity.me.selectedProjectName)
      .subscribe((response) => {
        sub.unsubscribe();

        this.loading = false;
        const newOutput = { input: savedInput, output: (<any>response).output };

        this.outputs.push(newOutput);
        this.input = '';
        this.scrollToBottom();

        setTimeout(() => {
          this.chatInput.nativeElement.focus();
        });
      });
  }

  public get eventHost() {
    return this.sizeHost.parentElement.parentElement;
  }

  public get sizeHost() {
    return this.content.nativeElement;
  }

  private _chatIndex = -1;
  onKeyDown(event: KeyboardEvent): void {
    const inputs = [...new Set(this.outputs.map((x) => x.input))];
    switch (event.key) {
      case 'ArrowUp':
        if (this._chatIndex + 1 >= inputs.length) {
          this._chatIndex = 0;
        } else {
          this._chatIndex++;
        }
        break;
      case 'ArrowDown':
        if (this._chatIndex <= 0) {
          this._chatIndex = inputs.length - 1;
        } else {
          this._chatIndex--;
        }
        break;
      case 'Escape':
        this._chatIndex = -1;
        this.input = '';
        break;
      default:
        return;
    }

    if (this._chatIndex > -1) {
      this.chatInput.nativeElement.value = inputs[this._chatIndex];
      const inputLen = this.chatInput.nativeElement.value.length;

      setTimeout(() => {
        this.chatInput.nativeElement.setSelectionRange(inputLen, inputLen);
        this.input = this.chatInput.nativeElement.value;
      }, 0);
    }
  }
}
